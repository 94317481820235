#congratulation {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 1);
}

#banner-container {
  display: flex;
  padding: 15px 16px 5px;
}

#heymandi-icon {
  width: 30px;
}

#banner {
  font-family: -apple-system, system-ui, sans-serif;
  padding-left: 8px;
}

#banner-header {
  width: fit-content;
  height: 17px;
  font-weight: 700;

  font-size: 14px;
  color: rgba(0, 0, 0, 1);

  line-height: 17px;
  letter-spacing: 0px;
}

#banner-desc { 
  font-size: 11px;
  color: rgba(0, 0, 0, 0.5);

  line-height: 13px;
  letter-spacing: 0px;
}

#open-button {
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;

  background: rgba(0, 122, 255, 1);
  border-radius: 14px;
  border: none;
  
  margin-left: auto;

  width: 68px;
  height: 28px;
}

#open-button > a {
  color: rgba(255, 255, 255, 1);
}

#logo-background {
  width: 100vw;
  height: 56px;
  background: linear-gradient(180deg, #00e9db 0%, #15feaa 100%);
}

#logo {
  width: 129.49px;
  padding-left: 14px;
}

#animation-container {
  height: 25%;
  position: relative;
}

#congratulation-container {
  text-align: center;
  padding-inline: 24px;
}

#congratulation-content-1 {
  margin-top: 10px;
}

#congratulation-content-2 {
  margin-top: 32px;
}

#download-button {
  margin-top: 20px;
  width: 155px;
}

#download-button-container {
  display: flex;
  justify-content: space-around;
  gap: 8px;
}

@media only screen and (min-width: 1024px) {
  #congratulation-container {
    min-width: 327px;
    width: 26vw;
    margin-inline: auto;
  }
}

@media only screen and (min-width: 2560px) {
  #download-button {
    width: 300px;
  }
}