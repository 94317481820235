body {
  margin: 0;
  font-family: "Poppins", "san-serif";
  min-height: 100vh;
  min-height: 100svh;
}

button {
  cursor: pointer;
}

a {
  cursor: pointer;
  text-decoration: none;
}

.position-absolute {
  position: absolute;
}

.flexbox {
  display: flex;
  justify-content: center;
}

/* Typography */

.h1 {
  font-weight: 700;
  font-size: 7.2vw;
  line-height: 11.2vw;
}

.body-1 {
  font-size: 4.27vw;
  line-height: 6.4vw;
}

.body-2 {
  font-size: 3.73vw;
  line-height: 5.6vw;
}

.fw-600 {
  font-weight: 600;
}

/* End of Typography */

#background {
  content: url("https://dteklg4bowqr.cloudfront.net/referral/website/background_mobile.png");
  width: 100vw;
}

#header-container {
  overflow: hidden;
}

#header {
  width: 90vw;
  padding-top: 10vw;
  padding-left: 7vw;
  padding-right: 0;
}

#header-logo {
  width: 33vw;
}

#header-content {
  color: rgba(255, 255, 255, 1);
}

#cookies-link {
  text-decoration: underline;
  color: white;
}

@media only screen and (min-width: 768px) {
  .body-1 {
    font-size: 3.27vw;
  }
}

@media only screen and (min-width: 1024px) {
  .h1 {
    font-size: 2vw;
    line-height: 3vw;
  }

  .body-1 {
    font-size: 1.11vw;
    line-height: 1.67vw;
  }

  .body-2 {
    font-size: 1vw;
    line-height: 1.5vw;
  }

  #background {
    content: url("https://dteklg4bowqr.cloudfront.net/referral/website/background_desktop.png");
  }

  #header {
    width: fit-content;
    padding: 5.5% 0 0 24%;
  }

  #header-logo {
    width: 8.68vw;
  }
}

@media only screen and (min-width: 2560px) {
  .body-1 {
    font-size: 0.8vw;
  }
}