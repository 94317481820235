#modal-background {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

#mobile-modal {
  min-height: 336px;
  height: 40vh;
  background-color: #fefefe;
  border: 0px;
  width: 100vw;

  bottom: 0;
  
  border-radius: 32px 32px 0 0;
  animation: animate-bottom 0.4s;
}

@keyframes animate-bottom {
  from {
    bottom: -300px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}

#mobile-modal-content {
  padding: 40px 24px 0px;
  text-align: center;
}

#email-link {
  display: block;
  width: 233px;
  height: 48px;
  border-radius: 24px;
  margin-top: 24px;

  letter-spacing: 0px;
  color: rgba(255, 255, 255, 1);

  background: linear-gradient(90deg, #00e1d4 0%, #00ec97 100%);
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

#cancel-button {
  margin-top: 10px;
  text-align: center;
  color: rgba(0, 0, 0, 0.3);
  background: none;
  border: none;
}

@media only screen and (min-width: 1024px) {
  #mobile-modal {
    animation: animate-bottom 0.4s;
    width: 390px;
    height: 320px;
    border-radius: 32px;
    
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .h1 {
      font-size: 28px;
      line-height: 48px;
    }

    .body-1 {
      font-size: 16px;
      line-height: 24px;
    }
  }
}